<template>
<div>
    <h6 class="mb-2">You can write a review about this order (This will be public)</h6>
    <vs-textarea label="Write Review" v-validate="{ required: true }" v-model="newReview" rows="2" name="message" />
    <span class="text-danger text-sm" v-show="errors.has('message')">{{ errors.first('message') }}</span>
    <div class="mt-2">
    <star-rating :rtl="$vs.rtl" :rating="preSetRating" @rating-selected="setRating" ></star-rating>
    </div>
    <div class="flex flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" id="loading-sendReview" :disabled="!validateForm" @click.prevent="createReview()" size="small">Save Review</vs-button>
    </div>
</div>

</template>

<script>
import StarRating from 'vue-star-rating'

export default {
    components:{
        StarRating
    },
    data() {
        return {
            showReview: false,
            newReview: "",
            preSetRating: 0
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        validateForm() {
            return ((this.preSetRating > 0) && !this.errors.any())
        },
        order(){
            return this.$store.state.orderList.order
        },
    },
    methods: {
        setRating: function(rating) {
        this.preSetRating = rating;
        },
        createReview(){
        this.$validator.validateAll().then(result => {
            if (result && this.order.id > 0) {
            let formData = new FormData()
            formData.append('data', JSON.stringify({
                "content": this.newReview,
                "rating": this.preSetRating,
                "order_id": this.order.id,
                "product_id": this.order.cart.product.id,
            }))
            this.resquestProcessor(formData,'create')
            }
        })
        },
        resquestProcessor(formData, action){
        this.$vs.loading({color: "#444", type: "sound"})
        this.$http.post(`reviews/${action}`, formData).then(response => {
            this.$vs.loading.close()
            if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.dispatch("orderList/fetchOrder",this.order.id)
            this.initializeAll()
            this.$validator.reset()
            }else if(response.data.errors){
            response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
            this.alertError(response.data.error)
            }
        }).catch((error)=>{console.log( error) })
        },
        initializeAll(){
        this.preSetRating = 0
        this.newReview = ""
        },

    }
}
</script>
