<template>
  <div id="order-view">
    <vs-alert color="danger" title="Order Not Found" :active.sync="order_not_found">
      <span>Order record with id: '{{ $route.params.orderId }}' not found. </span>
      <span>
        <span>Check </span><span @click="$router.push('/orders').catch(() => {})" class="text-inherit underline">All Orders</span>
      </span>
    </vs-alert>

    <div v-if="!isLoading">
      <div id="order-messaging">
        <vs-alert v-if="order.status === 'draft'" color="danger" class="mb-4" title="This order was saved as draft!" >
          <span class="text-sm">Proceed to make adjustment (if any) and payment so that this project can be processed</span>
          <a class="ml-4 mt-2" target="_blank" :href="`${$store.state.domain}/projects/${order.id}/review`" color="success">Continue Now</a>
        </vs-alert>

        <vs-alert v-if="order.status === 'delivered'" color="success" class="mb-4" title="Your order is delivered!" >
          <span class="text-sm">Please you are expected to access your order and make comment(s) where neccessary. If order is satisfied, mark order as completed or it will update automatically after 48 hours </span>
          <vs-button class="ml-4 mt-2" type="border" size="small" @click.prevent="confirmMarkCompleted" color="success">Mark Order Completed Now</vs-button>
        </vs-alert>

        <vs-alert color="warning" class="mb-3" title="Order Satisfied?" :active.sync="isNotifyReviewal" >
            <span>Write a brief review and rate this order. Your feedback means a lot to us. Thanks.<vs-button class="ml-4 mt-2" type="border" size="small" @click.prevent="popupAddReview = true" color="warning">Rate Now!</vs-button> <!-- <router-link :to=""> Rate Now!</router-link> --> </span>
        </vs-alert>

      </div>
      <div class="relative">
        <span v-if="countMessageNotifs" class="message-note-bell feather-icon-badge bg-warning text-white h-5 w-5 absolute rounded-full text-xs flex items-center justify-center">{{countMessageNotifs}}</span>
            <vs-tabs :value="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
                <!-- GENERAL -->
                <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? 'Overview' : ''">
                <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                    <order-overview class="mb-3" />
                    <order-requirements  class="mb-3" />
                    <order-delivered-files class="mb-3" />
                    <order-review class="mb-3"/>
                </div>
                </vs-tab>
                <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Messages' : ''">
                    <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
                        <order-messages />
                    </div>
                </vs-tab>
            </vs-tabs>
        </div>
        <vs-popup v-if="isNotifyReviewal" class="holamundo" @close="close" title="Submit Review" :active.sync="popupAddReview">
            <div class="mt-2">
                <review-form />
            </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import OrderOverview from "./OrderOverview.vue"
import OrderRequirements from "./OrderRequirements.vue"
import OrderDeliveredFiles from "./OrderDeliveredFiles.vue"
import OrderMessages from "./OrderMessages.vue"
import OrderReview from "./OrderReview.vue"
import ReviewForm from "./components/ReviewForm.vue"

export default {
  components: {
    OrderOverview,
    OrderRequirements,
    OrderDeliveredFiles,
    OrderMessages,
    OrderReview,
    ReviewForm
  },
  data() {
    return {
      isLoading: true,
      order_not_found: false,
      colorx: '#8B0000',
      popupAddReview: false
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    order(){
       return this.$store.state.orderList.order
    },
    countMessageNotifs() {
      return this.$store.getters['notification/countMessageNotifications'](this.order.id)
    },
    isNotifyReviewal(){
      return this.order.status === 'completed' && !this.order.is_reviewed;
    },
    activeTab(){
      return this.$route.query.tab
        ? +this.$route.query.tab
        : 0;
    },
    isNotifyApproval(){
      return (this.order.status == 'pending') && (this.order.is_started == 0)
    }
  },
  methods:{
    confirmMarkCompleted() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to update this order to completed.' ,
        accept: this.markAsCompleted,
        acceptText: "Proceed",
      })
    },
    markAsCompleted() {
      const formData = new FormData()
      this.$vs.loading({ color: "#444", type: "sound"})
      this.$http.post(`/orders/${this.order.id}/mark-completed`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.alertSuccess(response.data.success)
        }else{
          this.alertError(response.data.error)
        }
      })
      .catch(error => { console.log(error) })
    },
    fetchOrder(){
      this.isLoading = true
      this.$vs.loading({scale: 0.6});
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.isLoading = false
        }else{
          this.order_not_found = true
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancelled an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})

      this.popupAddReview = false
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.message-note-bell{
    position: absolute !important;
    top: -2px;
    z-index: 999;
    left: 62px !important;
}

@media(min-width:768px){
    .message-note-bell{
        top: 57px;
        left: 27px !important;
    }
}

.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}

</style>
