<template>
  <div v-if="showDelivery">
     <vx-card no-shadow class="mb-4">
      <div class="mb-4">
        <div v-html="delivery.message"></div>
        <p class="text-sm" style="color:#ccc;">Date: {{delivery.created_at | date_ago}}</p>
      </div>
      <div>
        <delivery-files v-if="delivery.files && delivery.files.length" :files="delivery.files" />
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col w-full mt-3">
          <ul class="list my-2">
            <li class="list__item" v-for="(comment, index) in delivery.comments" :key="index">
              <div class="w-full">
                <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
                  <div class="pt-2">
                    <feather-icon icon="ArrowUpIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                  </div>
                  <div>
                    <p>{{comment.text}}</p>
                    <div class="text-right">
                      <span class="opacity-50 text-sm">{{comment.created_at | date_ago}}</span>
                      <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initEditComment(comment)">Edit</a>
                      <a class="ml-2 text-sm text-danger" href="javascrip:void(0)" @click.prevent="confirmDeleteComment(comment.id)">Delete</a>
                      <div v-if="comment.replyer_id" class="pr-4 pt-4">
                        <h6 class="font-semibold text-sm italic">Response</h6>
                        <p class="mb-1"> {{comment.reply}} </p>
                        <small class="opacity-50">Replied {{comment.reply_date | date_ago}}</small>
                      </div>
                    </div>
                  </div>
               </span>
              </div>
            </li>
          </ul>
          <div class="mt-2 flex items-baseline py-2 bg-white">
            <vs-input class="flex-1" placeholder="Write your comment" v-model="newComment" @keyup.enter="createComment()" />
            <vs-button :disabled="!validateComment" class="bg-primary-gradient ml-2 flex items-center" type="filled" @click="createComment()"> <feather-icon v-if="isSmallerScreen" icon="SendIcon" class="" svgClasses="pt-1 w-4 h-4"> </feather-icon> {{isSmallerScreen ? '': 'Send'}}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <vs-popup class="holamundo" @close="close" title="Update Comment" :active.sync="popupEditComment">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onEdit.text" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateEdit" @click="updateComment()" size="small">Update</vs-button>
      </vs-popup>
  </div>
</template>

<script>
import DeliveryFiles from "./DeliveryFiles.vue"

export default {
   props: {
    delivery : { type: Object,  required: true, default: {} }
  },
  components: {
      DeliveryFiles
  },
  data() {
    return {
      showDelivery: false,
      awaitingDelete: null,
      newComment: "",
      popupEditComment:false,
      onEdit: {}
    }
  },
  computed: {
    validateEdit() {
      return this.onEdit.text != ""
    },
    validateComment() {
      return this.newComment != ""
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  methods: {
    initEditComment(comment){
      this.onEdit = {
        id: comment.id,
        text: comment.text,
      }
      this.popupEditComment = true
    },
    updateComment(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({text: this.onEdit.text}))
      this.resquestProcessor(formData,`${this.onEdit.id}/update`)
    },
    createComment(){
      if (!this.delivery.id) { return;}
      let formData = new FormData()
      formData.append('data', JSON.stringify({"text": this.newComment}))
      this.resquestProcessor(formData,`${this.delivery.id}/create`)
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/delivery-comments/${action}`, formData )
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.delivery);
          this.initializeAll()
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    confirmDeleteComment(commentId) {
      this.awaitingDelete = commentId
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete a comment.' ,
        accept: this.deleteComment,
        acceptText: "Proceed",
      })
    },
    deleteComment() {
      if(this.awaitingDelete < 1){return}
      this.$http.delete(`/delivery-comments/${this.awaitingDelete}/delete`)
      .then(response => {
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.delivery)
          this.initializeAll()
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    },
    initializeAll(){
      this.awaitingDelete = null
      this.popupEditComment = false
      this.onEdit = {}
      this.newComment = ""
      this.$validator.reset()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancelled an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
  created(){
    if(this.delivery.id){
      this.showDelivery = true
    }
  }
}
</script>

<style scoped>

</style>
