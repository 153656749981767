<template>
  <vx-card no-shadow >

    <div class="md:flex justify-between items-center">
      <div class="order-details inline-block">
        <h4 class="">Order: #DKO{{order.id}}
        </h4>
        <p class="text-sm" style="color:#ccc;">Date: {{order.created_at | date(true)}}</p>
      </div>
      <div class="price-holder">
        <div id="clockdiv">
          <table class="table m-0">
            <tbody class="text-center">
              <template v-if="timer.status">
                <tr class="head">
                  <td class="">Days</td>
                  <td class="">Hr</td>
                  <td class="">Min</td>
                  <td class="">Sec</td>
                </tr>
                <tr class="timedata">
                  <td> <h4 class="days de">{{ timer.daysSpan }}</h4></td>
                  <td class=""><h4 class="hours de">{{ timer.hoursSpan }}</h4></td>
                  <td class=""><h4 class="minutes de">{{ timer.minutesSpan }}</h4></td>
                  <td class=""><h4 class="seconds"></h4>{{ timer.secondsSpan }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <h4 class="text-danger notify-ex text-center">{{ getOrderMessage }}</h4>
                  <span v-if="order.status == 'completed'" class="text-sm"> {{order.completed_at | date(true) }} </span>
                  <span v-if="order.status == 'refunded'" class="text-sm"> {{order.refunded_at | date(true) }} </span>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <vs-divider/>

    <div class="flex justify-between">
      <span class="font-bold">for {{cart.product.name }} </span>
      <vs-dropdown vs-trigger-click class="cursor-pointer m-0">
        <vs-button color="#010101" class="inline" size="small">Options <feather-icon icon="ChevronDownIcon" svgClasses="h-3 w-4" /></vs-button>
        <vs-dropdown-menu style="min-width:150px">
          <vs-dropdown-item @click="$router.push(`/orders/${order.id}?tab=1`).catch(()=>{})">
            <span class="flex items-center">
              <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
              <span>Send&nbsp;Message</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div class="mt-3">
      <vs-table :data="[]"  noDataText="" :hoverFlat="true">
        <template slot="thead">
            <vs-th>
              Features
            </vs-th>
            <vs-th>
              Quantity
            </vs-th>
            <vs-th>
              Duration
            </vs-th>
            <vs-th v-if="cart.coupon">
              Discount
            </vs-th>
            <vs-th>
              Amount
            </vs-th>
        </template>

        <template>
          <vs-tr :key="0" >
            <vs-td >
              <h6> Package: <span class="text-success">{{orderPackage.name}} - {{order.currency_symbol}}{{orderPackage.price}}</span></h6>
              <order-feature-list :orderPackage="orderPackage" :addons="addons"/>
            </vs-td>
            <vs-td >
              1
            </vs-td>
            <vs-td>
              {{order.delivery_time | delivery_time}}
            </vs-td>
            <vs-td v-if="cart.coupon">
              <span  class="">{{cart.coupon.percent}}%</span>
            </vs-td>
            <vs-td>
              {{order.currency_symbol}}{{order.paid_amount | money_format}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <vs-divider/>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-4 mt-2" type="border" v-if="shouldMarkCompleted" size="small" @click.prevent="confirmMarkCompleted" color="success">Mark Order As Completed</vs-button>
    </div>
  </vx-card>
</template>

<script>

import OrderFeatureList from "./components/OrderFeatureList.vue"

export default {
  components: {
    OrderFeatureList,
  },
  data() {
    return {
      timer: {
        daysSpan: "",
        hoursSpan: "",
        minutesSpan: "",
        secondsSpan: "",
        status: false,
      },
      deadline: "",
      interval: "",
    }
  },
  watch: {
    order(){
      this.timerIgniter()
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    attributes(){
      return this.cart.attributes
    },
    addons(){
      return this.cart.addons
    },
    shouldShowProcess(){
      return (this.order.status === 'pending') && (this.order.has_paid == 1) && !this.isExpiredWhenPending
    },
    shouldMarkCompleted(){
      return this.order.status === 'delivered'
    },
    isExpiredWhenPending(){
      var now = new Date().getTime()
      var distance = this.deadline - now
      if(this.order.status === 'pending' && this.order.has_paid && distance < 0){
        return true;
      }else{
        return false
      }
    },
    getOrderMessage(){
      if(this.order.status == 'completed'){
        return 'Order Completed!'
      }else if(this.order.status == 'delivered'){
        return 'Order has been delivered!'
      }else if(this.order.status == 'refunded'){
        return 'Order was refunded!'
      }else if(this.order.status == 'draft'){
        return 'Saved as Draft!'
      }else if(!this.order.started_at){
        return 'Order Not Started!'
      }else {
        return 'Time has expired!'
      }
    }
  },
  methods: {
    timerCount(){
      var now = new Date().getTime()
      var distance = this.deadline - now
      if( distance > 0){
          this.calcTime(distance)
      }else{
        this.timer.status = false
        clearInterval(this.interval)
        return
      }
    },
    calcTime(dist){
      this.timer.daysSpan = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.timer.hoursSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
      this.timer.minutesSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)))
      this.timer.secondsSpan = this.toSlice(Math.floor((dist % (1000 * 60)) / 1000))
    },
    toSlice(t){
      return ('0' + t).slice(-2)
    },
    timerIgniter(){
      if(this.order.status === "pending" && this.order.is_started){
        let timeToDelivery = new Date(this.order.started_at).getTime() + (parseInt(this.order.delivery_time)*60*60*1000)
        this.deadline = new Date().setTime(timeToDelivery)
        this.timer.status = true
        this.timerCount()
        this.interval = setInterval(() => {
          this.timerCount()
        }, 1000)
      }
    },
    confirmMarkCompleted() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to update this order to completed.' ,
        accept: this.markAsCompleted,
        acceptText: "Proceed",
      })
    },
    markAsCompleted() {
      const formData = new FormData()
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/orders/${this.order.id}/mark-completed`, formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.alertSuccess(response.data.success)
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    }
  },
  mounted(){
    this.timerIgniter()
  },
  beforeDestroy(){
    clearInterval(this.interval)
  }
}

</script>

<style type="text/css" scoped>

    body,.text-sm{
        font-size: 12px;
    }

    sub{font-size: 12px;}

    #clockdiv{
        display: inline-block;
        float:left;
        margin-left:5px;
    }
    #clockdiv table .timedata td{
      background-color: #f8f8f8;
    }
    #clockdiv table .timedata td h4 {
        margin-bottom: 0px;
        color: #008b9c;
        font-size: 12px;
    }

    #clockdiv .notify-ex{
        font-size: 12px;
    }
    #clockdiv .table th, #clockdiv .table td{
        padding:3px 5px;
    }
    .price-holder{
        float:right;
        display:inline-block;
    }

    @media(max-width: 580px){
      .order-details{
        display: block;
        margin-bottom: 1.5em;

      }
      .price-holder{
        display:block;
        margin-bottom: 1.5em;
      }
    }
    .price-holder:first-child{
        font-size: 34px;
        color:#444;
        display: inline-block;
    }
    table thead tr{
        border-top:2px solid #ededed !important;
        background: #c2c6dc30 !important;
    }

    .table-responsive {
      display: block !important;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

      @media (max-width: 767.98px) {
        .table-responsive-md {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-md > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 991.98px) {
        .table-responsive-lg {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-lg > .table-bordered {
          border: 0;
        }
      }
    .icon-config{
      width:12px !important;
      height: 12px !important;
    }

</style>
