<template>
  <vx-card no-shadow>
    <div class="order-requirements justify-center items-center">
        <h6 class="text-center mb-0">Your buyer has filled out the requirements</h6>
        <vs-divider/>
          <div class="font-medium mb-4">
              <ul class="list">
                  <template v-for="(requiredField, reIndex) in requirements">
                      <li :key="reIndex" v-if="requiredField.answer"
                          class="list__item block">
                          <h6>✔️ {{requiredField.question}}</h6>
                          <div class="pl-4 pt-3" v-if="requiredField.key == 'logo_type'">
                            <img class="shadow" :src="`${$store.state.domain}/${requiredField.answer.path}`" :alt="requiredField.answer.name" width="100" /> 
                            <div class="color-type-name py-2">
                              <h6 class="mb-0">{{requiredField.answer.name}}</h6>
                            </div>
                          </div>
                          <div class="pl-4 pt-3" v-else-if="requiredField.key == 'color_types'">
                            <div class="vx-row m-0 color-types">
                              <div v-for="(colorType, clIndex) in requiredField.answer" :key="clIndex"
                                class="vx-col w-full p-2 md:w-1/4 mb-3">
                                  <div class="color-type shadow bg-white">
                                      <div class="vx-row m-0">
                                          <div v-for="(swatch, sIndex) in 9" :key="sIndex"
                                            :style="{'background-color': colorType.colors[sIndex]}"
                                            class="w-1/3 p-0 color-type-block">
                                                <span class="tooltiptext">{{colorType.colors[sIndex]}}</span>
                                          </div>
                                      </div>
                                      <div class="color-type-name py-2">
                                          <h6 class="mb-0 text-center">{{colorType.name}}</h6>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="text-sm pl-4 py-2" style="color:#aaa;">
                              {{requiredField.answer}}
                          </div>
                      </li>
                  </template>
              </ul>
          </div>
        </div>
        <vs-divider class="mb-0"/>
        <vs-list>
          <vs-list-header icon-pack="feather" icon="icon-image" title="Attached files">
          </vs-list-header>
        </vs-list>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 mb-4 w-full" v-for="(file, index) in files" :key="index">
            <a :href="`${$store.state.domain}/download?file_path=${file.path}`" :download="file.name" style="color:inherit;" class="downloadable">
              <!-- <img v-if="isImageFile(file.extension)" width="100" :src="`${$store.state.domain}/${file.path}`" :alt="file.name"> -->
              <div style="font-size: 8px;">
                  <h6>{{file.name}}</h6>
                  <span class="mr-2 text-sm"> size: {{file.size}}, ext.: {{file.extension}} </span>
              </div>
            </a>
          </div>
        </div>
  </vx-card>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    requirements(){
      return this.order.cart.requirements
    },
    files(){
      return this.order.order_files
    },

  }
}
</script>


<style scoped>
.order-requirements .color-types .color-type-block{
  height: 55px;
}

.order-requirements .color-types .color-type-block {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.order-requirements .color-types .color-type-block .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.order-requirements .color-types .color-type-block .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.order-requirements .color-types .color-type-block:hover .tooltiptext {
  visibility: visible;
  width: 70px;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transition: translate(-50%);
}

</style>