<template>
  <div>
    <!-- <vs-alert color="warning" title="Order Review" :active.sync="order_not_delivered">
      <span>You can only make review after when order is delivered. </span>
    </vs-alert> -->

    <div v-if="showReview" id="ReviewSection">
       <vx-card no-shadow>
        <ul class="list mb-4" v-if="review" >
          <li class="list__item px-0">
            <div style="width: 42px;" class="mr-3">
            <vs-avatar class="border-1 ml-0 border-solid border-white" :src="`${$store.state.domain}/${activeUser.image}`" size="42px" />
            </div>
            <div class="p-3 flex-grow rounded" style="background-color:#f8f8f8;">
              <h6 class="flex font-semibold"> {{activeUser.first_name}} {{activeUser.last_name}} <span class="ml-2 text-warning inline-flex"><feather-icon icon="StarIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>{{review.rating}}</span></h6>
              <p>{{review.content}}</p>
              <div> <span class="opacity-50 text-sm">{{review.created_at | date_ago}}</span>
               <!-- <a class=" ml-2 text-dark" href="javascrip:void(0)" @click.prevent="initUpdate()">Edit</a> -->
                <div v-if="review.responder_id" class="pl-4 pt-4">
                  <h6 class="font-semibold">{{order.seller.username}} response</h6>
                  <p class="mb-1"> {{review.response}} </p>
                  <small class="opacity-50">Published on {{review.responded_at | date}}</small>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-else>
          <review-form />
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ReviewForm from './components/ReviewForm.vue'

export default {
  components: {
    ReviewForm
  },
  data() {
    return {
      showReview: false,
      order_not_delivered: false,
    }
  },
  computed:{
    activeUser(){
        return this.$store.state.AppActiveUser
    },
    order(){
      return this.$store.state.orderList.order
    },
    review(){
      return this.order.review
    }
  },
  methods: {
    setRating: function(rating) {
      this.preSetRating = rating;
    },
  },
  created(){
    if((this.order.status == "delivered") || (this.order.status == "completed")){
      this.showReview = true
    }else{
      this.order_not_delivered = true
    }
  }

}
</script>

<style type="text/css">
  .vue-star-rating-star{
    height: 25px;
    width:25px;
  }
  .vs-con-textarea{
    margin-bottom: 0;
  }
</style>
